.carousel-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 400px;
  }
  
  .carousel-images {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: calc(100% * 3);
    transition: transform 0.3s ease-in-out;
  }
  
  .carousel-image {
    width: 33.33%;
    height: 100%;
  }
  
  .carousel-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .carousel-controls {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .carousel-control {
    background-color: rgba(255, 255, 255, 0.7);
    color: #333;
    border: none;
    outline: none;
    font-size: 24px;
    padding: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .carousel-control:hover {
    background-color: rgba(255, 255, 255, 1);
  }


@media (max-width: 600px) {
  .carousel-container {
    height: 300px;
    /* Adjust height for smaller screens */
  }

  .carousel-images {
    /* No specific changes needed here for mobile */
  }

  .carousel-image {
    /* No specific changes needed here for mobile */
  }

  .carousel-image img {
    /* Ensure images scale correctly on smaller screens */
    object-fit: contain;
  }

  .carousel-controls {
    /* Adjust control sizes and positions for better usability on mobile */
    padding: 0 10px;
  }

  .carousel-control {
    font-size: 20px;
    /* Slightly reduce font size */
    padding: 12px;
    /* Adjust padding for smaller screens */
  }

  /* Add any additional mobile-specific styles below */
}