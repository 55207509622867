.fikerteb{
    background-color: #78CBF9E0;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding: 8px 8px;
    gap: 30px;
    margin: auto;
    margin-bottom: 40px;
    width: fit-content;
    border-radius: 40px;
    margin-right: 300px;
    /* margin-left:100px; */
       }
    .box{
        width: 600px;
        justify-content: start;
        text-align: start;
        background-color:rgba(38, 35, 31, 0.1);
        border-radius: 40px;
        }
    .info{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-left:0px;
        
    }
    .info img{
    border-radius: 40px;
    align-items: flex-start;
    }
    .infoInner{
    text-align: start;
    padding-left: 20px;
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 10px;    
    }
    .box p{
        font-family: 'Roboto Flex';
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
           
    }

    .replay{
        width: inherit;
        align-items: center;
        margin: auto;
        
    }
    

/* Media Query for Mobile Devices */
@media (max-width: 600px) {
    .fikerteb {
        margin-right: 0;
        width: auto;
        /* Adjust width for mobile screens */
        padding: 8px;
        /* Adjust padding if needed */
        gap: 15px;
        /* Reduce gap for a tighter layout */
    }

    .box {
        width: 100%;
        /* Full width boxes on mobile */
        padding: 8px;
        /* Adjust padding for mobile */
    }

    .info {
        flex-direction: column;
        /* Stack info content vertically */
        align-items: center;
        /* Center align items for a better mobile layout */
        margin-left: 0;
    }

    .info img {
        width: 100%;
        /* Adjust image width to fit mobile screens */
        height: auto;
        /* Maintain aspect ratio */
    }

    .infoInner {
        font-size: 16px;
        /* Adjust font size for mobile */
        line-height: normal;
        /* Adjust line height */
        padding-left: 10px;
        /* Adjust padding */
    }

    .box p {
        font-size: 15px;
        /* Adjust paragraph font size */
    }

    .replay {
        width: 100%;
        /* Full width for replay section */
    }

    /* Add any additional mobile-specific styles below */
}