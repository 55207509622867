.socialMedia{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 35px;
    gap: 16px;
    width: 50px;
    height: fit-content;
    margin-top: -140px;
    /* Black color 5% */
    
    background: rgba(38, 35, 31, 0.1);
    border-radius: 40px;
}

/* Media Query for Mobile Devices */
@media (max-width: 600px) {
    .socialMedia {
        z-index: 99;
        position: fixed;
        /* Keep it fixed, but adjust the location for mobile */
        top: 50%;
        /* Position it at the bottom of the screen */
        left: 2%;
        /* Center it horizontally */

        /* transform: translateX(-80%); */
        /* Adjust for center alignment */
        
        width: auto;
        /* Auto width to accommodate the contents */
        height: 45px;
        /* Set a fixed height */
        /* margin-bottom: 22em; */
        /* Reset margin-top */

        padding: 17em 0.3em;
        /* Reduce padding */

        flex-direction: column;
        /* Change direction to row for horizontal layout */
        gap: 25px;
        /* Reduce gap between icons */
        border-radius: 25px;
        /* Adjust border-radius for a rounded look */
        background: rgba(38, 35, 31, 0.2);
        /* Slightly darker background for visibility */
    }

    .socialMedia > img {
        width: 50px;
    }

    /* Additional styles for child elements (like icons) can be added here if necessary */
}


/* @media only screen and (max-width: 600px){
    .socialMedia {
            position: fixed;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 35px;
            gap: 16px;
            width: 30px;
            height: fit-content;
            margin-top: -140px;
    
            background: rgba(38, 35, 31, 0.1);
            border-radius: 40px;
        }
} */