.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.all{
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: space-around;
  width: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
h2{

  font-family: 'Roboto Flex';
font-style: normal;
font-weight: 300;
font-size: 60px;
line-height: 60px;
  color: #F4A138;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  flex: none;
order: 1;
flex-grow: 0;

}
.top{
  display: flex;
  justify-content: space-between;
}