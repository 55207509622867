.teamMembers{
display: flex;
height: fit-content;
left: 16px;
top: 196px;

/* Black color 5% */

background: rgba(38, 35, 31, 0.1);
border-radius: 40px;
margin-bottom: 30px;
}
.teamMembers p{
font-family: 'Roboto Flex';
font-style: normal;
font-weight: 700;
font-size: 19px;
line-height: 30px;
/* or 143% */

text-align: start;
}
.teaminfo{
    display: flex;
    justify-content: baseline;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px;
}

.info1{
    margin-top: -20px;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 20px;
}
.teamMembers img{
width: 136px;
height: 136px;
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
border-radius:50%;
}

/* Media Query for Mobile Devices */
@media (max-width: 600px) {
    .teamMembers {
        display: flex;
        flex-direction: column;
        /* Stack team members vertically */
        align-items: center;
        /* Center-align the items */
        height: auto;
        /* Adjust height for mobile */
        left: 0;
        /* Adjust left position */
        top: auto;
        /* Adjust top position */
        margin-bottom: 20px;
        /* Adjust bottom margin */
        padding: 15px;
        /* Adjust padding */
        background: rgba(38, 35, 31, 0.1);
        border-radius: 20px;
        /* Adjust border-radius */
    }

    .teamMembers p {
        font-size: 17px;
        /* Adjust font size for mobile */
        text-align: center;
        /* Center text for mobile */
        line-height: normal;
        /* Adjust line height */
    }

    .teaminfo {
        padding: 15px;
        /* Adjust padding */
        align-items: center;
        /* Center-align the items */
    }

    .info1 {
        margin-top: 0;
        /* Adjust top margin */
        padding: 10px;
        /* Adjust padding */
        text-align: center;
        /* Center text for mobile */
    }

    .teamMembers img {
        width: 100px;
        /* Adjust image size for mobile */
        height: 100px;
        /* Adjust image size for mobile */
        border-radius: 50%;
        /* Maintain circular shape */
    }

    /* Add any additional mobile-specific styles below */
}