.aboutUs{
display: flex;
flex-direction: column;
align-items: center;
padding: 24px 16px;
gap: 80px;
position: relative;
width: 800px;
height: fit-content; 
margin-right: 250px;
margin-bottom: 50px;

/* Primary color */

background: #F4A138;
border-radius: 40px;
}
.contant{
width: 100%;
height: max-content;
padding: 10px;
background: rgba(255, 255, 255, 0.57);
border-radius: 40px;

}
.contant h1{
margin: auto;
padding:22px;
position: relative;
gap: 16px;
width: 100%;
height: fit-content;
/* left: 367px;
top: 23px; */
margin-top: 20px;
/* Black color 5% */
margin-bottom: 40px;
background: rgba(38, 35, 31, 0.1);
border-radius: 40px;
font-family: 'Roboto Flex';
font-style: normal;
font-weight: 600;
font-size: 40px;
line-height: 50px;
}
.contant p{
display: flex;
flex-direction: column;
justify-content: flex-start;
text-align: left;
padding-left: 20px;
padding-right: 20px;
font-family: 'Roboto Flex';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 30px;
margin-top: -10px;
}
.contant h3{
    font-size: 21px;
    font-family: 'Roboto Flex';
    font-weight: bold;
    font-weight: 900;
    text-align: left;
    padding-left: 20px;
}
.slider{
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 16px;
gap: 8px;
width: 100%;
height: 377.8px;

/* Secondary color */

background: rgba(120, 203, 249, 0.88);
border-radius: 40px;
}

.products p{
font-family: 'Roboto Flex';
font-style: normal;
font-weight: 700;
font-size: 17px;
line-height: 30px;

}
.products h3{
    font-weight: 700;
    margin-top: 30px;
}
.facebook{
    margin-bottom: 40px;
}

/* Media Query for Mobile Devices */
@media (max-width: 600px) {
    .aboutUs {
        width: 100%;
        margin-right: 0;
        gap: 40px;
        padding: 24px;
    }

    .contant {
        padding: 10px;
        border-radius: 20px;
    }

    .contant h1 {
        font-size: 30px;
        line-height: 40px;
        margin-top: 10px;
        margin-bottom: 20px;
        padding: 10px;
        border-radius: 20px;
    }

    .contant p {
        font-size: 16px;
        line-height: 25px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .contant h3 {
        font-size: 18px;
        padding-left: 10px;
    }

    .slider {
        display: block;
        height: auto;
        padding: 8px;
        gap: 4px;
        border-radius: 20px;
    }

    .products p {
        font-size: 15px;
        line-height: 25px;
    }

    .products h3 {
        margin-top: 20px;
    }

    .facebook {
        margin-bottom: 20px;
    }

    /* Add any additional mobile-specific styles below */
}
