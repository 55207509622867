*{
    box-sizing: border-box;
}
body{
    margin: 0;
    background-image:linear-gradient(to right,#92FEB0,#00C9FF);
}
.nav{
    display: flex;
    justify-content: center;
    padding: 1rem;
}

.header{
margin: auto;

}
.logoImage{
padding-top: 20px;
}
.nav.logo-list{
padding-left: 10rem;
}


nav :first-child{
color: black;
font-family: 'Roboto Flex';
font-style: normal;
font-weight: 700;
font-size: 35px;
line-height: 30px;
text-decoration: none;
    
}
.nav-links ul{
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    /* flex: 0.8; */
    justify-content: center;
    background: rgba(38, 35, 31, 0.1);
    border-radius: 40px;

}
.nav-links li{
    background-color: #FFB554;
    border-radius: 24px;
    margin: 8px;
    padding: 5px 15px;    
    }


.header-text{
    display: flex;
    justify-content: space-around;
    column-gap: 2rem;
    row-gap: 10px;
    margin: auto;
}



.facebook{
    margin-right: 300px;
}
.facebook img{
    width: 600px;
}
.video{
    display: grid;
    background-color: #FFB554;
    width: 800px;
    height: fit-content;
    justify-items: auto;
    margin: 100px;
    margin-left: -80px;
}
.video iframe{
    width: 90%;
    background-color: #26231F80;
    height: 500px;
}

.book{
    display: flex;
    justify-content: space-evenly;
    
}
.bookDescription{
    width: 50%;
    text-align: left;
}

.firstHead{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 800px;
    height: 624px;
    padding: 16px 16px;
    gap: 32px;
    background: rgba(120, 203, 249, 0.88);
    border-radius: 40px;
    margin-left: -110px;
    margin-bottom: 40px;
}

.card{
display: flex;
justify-content: space-between;
}
.info{
    margin-left: 90px;
}
.foot{
display: flex;
justify-content: space-around;
align-items: center;
background: rgba(120, 203, 249, 0.88);
border: 1px solid #000000;
border-radius: 40px;
height: 112px;
}
.foot a{
text-decoration: none;
color: #000000;
font-style: normal;
font-weight: 600;
font-size: 42px;
line-height: 50px;
}
.foot p{
font-weight: 600;
font-size: 21px;
line-height: 30px;
}
.contact{
width: 210px;
height: 56px;

/* Tertiary color */

background: #FFB554;
border-radius: 24px;
}

.contact a{
    font-size: 40px;
}

.goUp{
    background-color: #FFB554;
    display: flex;
    
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 24px;
    border-radius: 40px;
    width: 150px;
    height: 64px;
   
}
.goUp img{
    width: min-content;
}
.gallaryContainer{
    background: #FFB554;
    border-radius: 20px;   
    width: fit-content;
    margin-bottom:40px;
    margin-left: -100px;
    padding: 20px;
}
.gallary{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 32px 24px;
gap: 40px;
margin:auto;
width: fit-content;
height: fit-content;

/* margin-top: 40px; */
/* Tertiary color */


}
.gallary img{
    width: 200px;
}


.navbarCollapsed {
    display: none;
}

.menuIcons {
    z-index: 900;
    position: relative;
    display: none;
}



@media (max-width: 600px) {
    
    
    /* .nav {
        flex-direction: column;
        align-items: center;
    }

    .nav.logo-list {
        padding-left: 0;
    }

    .nav ul {
        flex-direction: column;
        align-items: center;
    } */

    .video
     {
        flex-direction: column;
        align-items: center;
        margin-left: 0;
        width: 100%;
    }
    .card{
        flex-direction: column;
        align-items: center;
        margin-left: 0;
        width: 100%;
    }

    .gallary {
        flex-direction: column;
        align-items: center;
        margin-left: 0;
        /* width: 100%; */
    }

    .header {
        width: 100%;
    }
    .header-text {
        flex-direction: column;
        align-items: center;
        margin-left: 0;
        width: 100%;
    }

    .firstHead
    {
        flex-direction: column;
        align-items: center;
        margin-left: 0;
        width: 100%;
        height: 50em;
    }

    .book {
        flex-direction: column;
        align-items: center;
        margin-left: 0;
        width: 100%;
    }

    .book > img {
        width:50%;
    }

    .video iframe,
    .bookDescription,
    .gallary img {
        width: 100%;
        height: auto;
    }

    .facebook > img {
        width: 25em;
        height: auto;
    }

    .video {
        margin: 0;
    }

    .firstHead {
        width: auto;
        margin-bottom: 20px;
    }

    .info {
        margin-left: 0;
    }

    .gallaryContainer {
        margin-left: 0;
    }

    /* responsive mobile navbar */
    .nav {
		display: none;
	}

	.navbarItems {
		display: none;
	}

	/* adjusted margin */
	.menuIcons{
	z-index: 908;
    top: -13em;
	margin-left: 25em;
	position: relative;
	display: block;
}

	.fa-bars, 
	.fa-times{
		color: white;
	}


    .nav {
        flex-direction: column;
        align-items: center;
    }

    .nav ul {
        flex-direction: column;
        align-items: center;
    }

	.navbarCollapsed{
		display: block;
		width: 100%;
		margin-top: 0%;
		margin-bottom: -130px;
		z-index: 907;
	}

/* nav :first-child{
color: black;
font-family: 'Roboto Flex';
font-style: normal;
font-weight: 700;
font-size: 35px;
line-height: 30px;
text-decoration: none;
    
} */
.navbarCollapsed ul{
    padding: 0;
    margin: 0;
    list-style: none;
    display: block;
    /* flex: 0.8; */
    justify-content: center;
    background: rgba(38, 35, 31, 0.1);
    border-radius: 40px;

}
.navbarCollapsed li{
    background-color: #FFB554;
    border-radius: 24px;
    
    margin: 25px 8px;
    padding: 8px 35px;    
    }

.nav-links{
	font-family: DM Sans;
	width: 50%;
	padding: 40px 0;
	display: flex;
    flex-direction: column;
	margin-inline-start: 0px;
	padding: 12px 75px;
	justify-content: center;
	align-items: center;
	list-style: none;
	gap: 90px;
	text-decoration: none;
}

.navMenu {
    font-family: DM Sans;
    display: flex;
    flex-direction: column;
    padding: 12px 75px;
    justify-content: center;
    align-items: center;
    background-color: rgba(59, 59, 59, 0.7);
    box-shadow: 0 8px 32px rgba(31, 38, 135, .35);
    backdrop-filter: blur(4px);
    border-radius: 13px;
    width: 100%;
    height: auto;
    position: absolute;
    top: -110%;
    left: 0%;
    transition: all 0.3s ease-in;
    /* align-items: stretch; */
    padding: 100px 0 30px 0;
    margin: 0;
    /* align-items: center; */
    list-style: none;
    gap: 40px;
    text-decoration: none;
    z-index: 907;
}

.menuIcons {
    margin-right: 320px;
    margin-top: -50px;
    display: block;
}

.navMenu.active {
    top: 0%;
    transition: all 0.3s ease-out;
}

.navAndText {
    width: 400px;
}

.text_head {
    margin-bottom: 100px;
}
.foot{
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background: rgba(120, 203, 249, 0.88);
    border: 1px solid #000000;
    gap: 10px;
    border-radius: 40px;
    height: fit-content; 
}
}