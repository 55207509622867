.more{
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 24px 32px;
gap: 10px;
margin: auto;
width: fit-content;
height: fit-content;

/* Error */

background: #FF2358;
border-radius: 40px;

}  
.more img{
    width: min-content;
}
.more a{
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    gap: 32px;
    text-decoration: none;
    width: 224px;
    height: 40px;    
}
.readme{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 32px;
    gap: 5px;
    margin: auto;
    width: fit-content;
    height: 88px;
    margin-bottom: none;
    /* Primary color */
    
    background: #F4A138;
    border-radius: 40px;
}
.readme a{
    color: white;
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 0px;
gap: 32px;
text-decoration: none;
width: 224px;
height: 40px;
}
.readme img{
    transform: rotate(90deg);
    
}
.readme1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 32px;
    gap: 5px;
    margin: auto;
    width: fit-content;
    height: 58px;
    margin-bottom: none;
    /* Primary color */
    background: #FAD7A9;
    border-radius: 40px;
}
.readme1 a{
    color: white;
    display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 0px;
gap: 32px;
text-decoration: none;
width: 224px;
height: 40px;
}
.readme1 h3{
    color: black;
    font-size: larger;
    font-weight: bolder;
}

/* Media Query for Mobile Devices */
@media (max-width: 600px) {

    .more,
    .readme,
    .readme1 {
        width: auto;
        /* Full width on mobile */
        padding: 16px;
        /* Adjust padding for smaller screens */
        gap: 8px;
        /* Reduce gap for a compact layout */
        margin: 10px 0;
        /* Adjust margin for mobile layout */
    }

    a > .readmoreimg > img {
        width: 40px;
        aspect-ratio: 1;
    }

    .readme img {
        width: fit-content;
        /* Full width images on mobile */
    }

    .more  {
        display: flex;
        align-items: center;
        margin-left: 3.5em;
    }

    .more > a img {
        width: 40px;
        aspect-ratio: 1;
    }
    

    .readme a,
    .readme1 a {
        width: 100%;
        /* Full width for links */
        gap: 16px;
        /* Adjust gap for mobile */
        padding: 8px;
        /* Adjust padding for mobile */
    }

    .readme img {
        width: fit-content;
        transform: rotate(0deg);
        /* Adjust image rotation for mobile */
    }

    .readme1 h3 {
        font-size: medium;
        /* Adjust font size for mobile */
    }

    /* Add any additional mobile-specific styles below */
}