.fikerte{
background-color: #78CBF9E0;
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
padding: 8px 8px;
gap: 30px;
margin: auto;
margin-bottom: 40px;
width: fit-content;
border-radius: 40px;
margin-left:10px;

}
.box{
    width: 600px;
    text-align: start;
    background-color:rgba(38, 35, 31, 0.1);
    border-radius: 40px;
    }
div .info{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left:0px;
    
}
.info img{
border-radius: 40px;
align-items: flex-start;
}
.infoInner{
text-align: start;
padding-left: 20px;
font-family: 'Roboto Flex';
font-style: normal;
font-weight: 600;
font-size: 19px;
line-height: 10px;    
}
.box p{
    font-family: 'Roboto Flex';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
       
}
.replay{
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    
    gap: 80px;
    position: relative;
    width: fit-content;
    height: fit-content; 
    margin-right: 250px;
    margin-bottom: 50px;
    
    /* Primary color */
    
    background: #F4A138;
    border-radius: 40px;
    }


/* Media Query for Mobile Devices */
@media (max-width: 600px) {
    .fikerte {
        width: auto;
        /* Adjust width for mobile screens */
        padding: 8px;
        /* Adjust padding if necessary */
        margin-left: 0;
        /* Adjust left margin for mobile screens */
    }

    .box {
        width: 100%;
        /* Full width boxes on mobile */
    }

    div .info {
        flex-direction: column;
        /* Stack info content vertically */
        align-items: flex-start;
        /* Align items to the start */
    }

    .info img {
        width: 100%;
        /* Full width images on mobile */
        height: auto;
        /* Maintain aspect ratio */
    }

    .infoInner {
        font-size: 17px;
        /* Adjust font size for mobile */
        padding-left: 10px;
        /* Adjust padding */
        line-height: normal;
        /* Adjust line height */
    }

    .box p {
        font-size: 15px;
        /* Adjust paragraph font size */
    }

    .replay {
        width: auto;
        /* Full width for replay section on mobile */
        margin-right: 0;
        /* Adjust right margin */
        gap: 40px;
        /* Reduce gap for a tighter layout */
    }

    /* Add any additional mobile-specific styles below */
}