.contactUs{
    margin-top: -1em;
}
h3.contactUs{
    color: #ffffff;
    /* font: 2em; */
    font-size: xx-large;
    align-self: top;
    text-align: start;
}
p.contactUs{
    color: #ffffff;
    /* font: 3em;   */
    font-size: x-large;
    font-weight: bold;
    text-align: center;
    margin-top: -3em;
}
input.info{
    float: left;
    width: 60%;
    background-color: #FAD7A9;
    padding-left: 1em;
    border-color: inherit;
    border-radius: 2em;
    height: 2.5em;
    margin-bottom: 1em;
    font-size: large;
}
input.sendMessage{
    width: fit-content;
}
.contactUsForm{
    
    margin-top: -4em;
}
textarea.message{
    width: 100%;
    text-align: left;
    background-color: #FAD7A9;
    text-decoration-style: inherit;
    border-color: inherit;
    border-radius: 2em;
    height: 7em;
    margin-bottom: 1em;
    font-size: large; 
    padding-left: 1em;
    padding-top: 1em;
}

input[type="submit"] {
    margin: 10px;
    padding-right: 30px;
}

span {
    position: relative;
}

span:after {
    font-family: FontAwesome;
    content: "\f004"; /* Value may need to be changed in newer version of font awesome*/
    font-size: 13px;
    position: absolute;
    right: 20px;
    top: 1px;
    pointer-events: none;
}
p.thanks{
    color: #ffffff;
    /* font: 3em;   */
    font-size: x-large;
    font-weight: bold; 
}

/* Media Query for Mobile Devices */
@media (max-width: 600px) {
    .contactUs {
        margin-top: 0;
        /* Adjust margin for mobile view */
    }

    h3.contactUs,
    p.contactUs,
    p.thanks {
        font-size: large;
        /* Adjust font size for mobile */
        text-align: center;
        /* Center align for better mobile readability */
    }

    p.contactUs {
        margin-top: 0;
        /* Adjust top margin */
    }

    input.info,
    textarea.message {
        float: none;
        /* Disable floating for a better layout */
        width: 90%;
        /* Adjust width for mobile devices */
        margin: 0.5em auto;
        /* Center align and adjust margin */
        font-size: medium;
        /* Adjust font size for mobile */
    }

    input.sendMessage {
        width: 90%;
        /* Full width for send message button */
        margin: 0.5em auto;
        /* Adjust margin */
    }

    .contactUsForm {
        margin-top: 0;
        /* Adjust top margin for the form */
    }

    span:after {
        right: 5px;
        /* Adjust position for mobile */
    }

    input[type="submit"] {
        padding: 10px 20px;
        /* Adjust padding for mobile */
    }

    /* Add any additional mobile-specific styles below */
}